/**
 * useCtaTarget Composable
 *
 * Purpose:
 * This composable provides functionality to determine the target of a CTA (Call to Action) button
 * and navigate to the appropriate section, modal.
 *
 * Returns:
 * - navigateToTarget: A function that, given certain parameters, determines the target and performs the navigation.
 *
 * Usage Example:
 * ```javascript
 * const { navigateToTarget } = useCtaTarget();
 * ```
 */

export function useCtaTarget() {
  const { openModal } = useModalStore()
  const { openModal: openCourseModal } = useCourseModalStore()

  /**
   * Navigate to the appropriate target based on the provided parameters.
   *
   * @param isCourseModal - Boolean indicating if the target is a course modal.
   * @param targetType - A string indicating the type of target ('section', 'modal').
   * @param targetId - The ID or path of the target.
   * @param courseSlug - The slug of the course, if relevant.
   */
  function navigateToTarget(isCourseModal: boolean, targetType: string, targetId: string, courseSlug: string) {
    if (isCourseModal) {
      openCourseModal(courseSlug, targetType, targetId)
      return
    }

    switch (targetType) {
      case 'section': {
        // Scroll smoothly to the section element
        const element = document.querySelector(`#${targetId}`)
        if (element) { element.scrollIntoView({ behavior: 'smooth' }) }

        break
      }
      case 'modal': {
        // Open the appropriate modal based on the target ID
        if (targetId === 'course-modal') { openCourseModal(courseSlug, targetType, targetId) }
        else { openModal(targetId) }

        break
      }
      default:
        break
    }
  }

  return {
    navigateToTarget,
  }
}
